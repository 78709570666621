import React from 'react';
import styled from 'styled-components';
import SEO from "utils/seo";
import { graphql } from 'gatsby';
import { BlogList } from 'components';

const BlogWrapper = styled.section`
	width: 100%;
	height: 100%;
	background-color: var(--background-color);
	color: white;
`;

export const blogQuery = graphql`
	{
		prismic {
			allBlogs {
				edges {
					node {
						seo_description
						page_title
						image
          	imageSharp {
            childImageSharp {
              fluid{
								...GatsbyImageSharpFluid_withWebp
              	}
            	}
          	}
            _meta {
              uid
            }
					}
				}
			}
		}
	}
`;

const Blog = ({ data }) => {
  if(!data){
    return null;
	}
	
	const posts = data?.prismic?.allBlogs?.edges;
	
	return (
		<>
		<SEO title={"Our Blog"} description={"A list off articles by us"}/>
		<BlogWrapper>
			<h2>Blog</h2>
			<BlogList posts={posts}/>
		</BlogWrapper>
		</>
	);
};

export default Blog;
